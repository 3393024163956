import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
// import { Link } from "react-router-dom"
import { t } from "../../../../config"
import { homeSearchToogle } from "../../../redux/actions/SearchAction"
import styles from "./landing-container.less"

const Landing = props => (
    <div className={`container ${styles.bannerContainer}`}>
        {/* <Link className={styles.searchForm} to="/search"> */}
        <div className={styles.bannerTextmain}>
            <div className={styles.bannerTextEn}>
                {props.bannerText}
            </div>
        </div>
        <a
            onClick={() => props.homeSearchToogle(true)}
            className={styles.searchForm}
        >
            <form>
                <label htmlFor={t("Search")} aria-label="Search" />
                <input
                    type="text"
                    value=""
                    placeholder={t("Where do you want to go?")}
                    className={styles.inputText}
                    data-test-id="input-search"
                />
                <input type="submit" value="" className={styles.searchBtn} />
            </form>
        </a>
        {/* </Link> */}
    </div>
)
Landing.propTypes = {
    homeSearchToogle: PropTypes.func.isRequired,
}
const mapStateToProps = state => ({
    ...state.SearchReducer,
})
export default connect(mapStateToProps, { homeSearchToogle })(Landing)
